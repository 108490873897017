<template>
  <div style="width:100%">
    <center><span class="text-h6"><b>DATOS DE LA LLAMAS</b></span></center>
    <br><br>
    <v-container>
    <b>TELEFONO:</b> {{ llamada.celular }}
    <br>
    <b>TIPO:</b> {{ llamada.tipo }}
    <br>
    <b>UNIQUE ID:</b> {{ llamada.uniqueid }}
    <br>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Telefonia',
  components: {
  },
  data: () => ({
    celular: '',
    callercode: '',
    tipo: '',
    cedula: '',
    uniqueid: '',
    security_phrase: '',
    lead_id: '',
    vendor_id: '',
    closer: '',
    server_ip: '',
    closecallid: '',
    xfercallid: ''
  }),
  created () {
    this.celular = this.$route.query.Celular || ''
    this.callercode = this.$route.query.CallerCode || ''
    if (this.callercode !== '') {
      const tipo = this.callercode.substring(0, 1)
      if (tipo === 'M') {
        this.tipo = 'OUTBOUND'
      }
      if (tipo === 'Y') {
        this.tipo = 'INBOUND'
      }
    }
    this.cedula = this.$route.query.Cedula || ''
    this.uniqueid = this.$route.query.Uniqueid || ''
    this.security_phrase = this.$route.query.security_phrase || ''
    this.lead_id = this.$route.query.lead_id || ''
    this.vendor_id = this.$route.query.vendor_id || ''
    this.closer = this.$route.query.closer || ''
    this.server_ip = this.$route.query.server_ip || ''
    this.closecallid = this.$route.query.closecallid || ''
    this.xfercallid = this.$route.query.xfercallid || ''
    const routeData = this.$router.resolve({ path: '/vicidial', query: { celular: this.celular, callercode: this.callercode, tipo: this.tipo, cedula: this.cedula, uniqueid: this.uniqueid, security_phrase: this.security_phrase, lead_id: this.lead_id, vendor_id: this.vendor_id, closer: this.closer, server_ip: this.server_ip, closecallid: this.closecallid, xfercallid: this.xfercallid } })
    window.open(routeData.href, '_blank')
  },
  methods: {
  }
}
</script>
