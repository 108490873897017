<template>
  <div style="width:100%">
  <v-card>
    <v-card-text class="pt-1 pr-4 pl-4 pb-1">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <v-text-field label="TIPO" :value="llamada.tipo" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-text-field label="TELEFONO" :value="llamada.celular" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <v-text-field label="UNIQUE ID" :value="llamada.uniqueid" disabled></v-text-field>
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
  <br>
  <v-form ref="form">
      <v-card>
        <v-card-text class="pt-1 pr-4 pl-4 pb-1">
          <!--  <v-row no-gutters>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="pqr.tipoatencion_id" v-on:input="input_tipoatencion(pqr.tipoatencion_id)" item-text="descripcion" item-value="id" :loading='loadingAtenciones' :items="items_atenciones" label="TIPO DE ATENCION"></v-autocomplete>
              </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
      <br>
      <buscarForm :rules="rules" :colores="colores" :session="session" :pqr="pqr" :items_busqueda="items_busqueda"></buscarForm>
      <br>
      <registradorForm  :registrador="registrador" :colores="colores" :rules="rules" :loadingDepartamento="loadingDepartamento" :items_departamentos="items_departamentos" :items_tipoUsuario="items_tipoUsuario" :items_tipoIdentificacion="items_tipoIdentificacion" :items_tipoComite="items_tipoComite" :items_votoBlanco="items_votoBlanco"></registradorForm>
      <!-- <ciudadanoForm  :ciudadano="ciudadano" :colores="colores" :rules="rules" :loadingPaises="loadingPaises" :items_paises="items_paises"></ciudadanoForm> -->
      <br>
      <pqrForm :tokenNuevo="tokenNuevo" :items_archivos="items_archivos" :session="session" :alerta="alerta" :pqr="pqr" :rules="rules" :colores="colores" :loadingSubcategoria="loadingSubcategoria" :items_subcategorias="items_subcategorias" :items_niveles="items_niveles" @remover_niveles="eliminarNiveles" @new_niveles="agregarNiveles" @add_archivos="agregar_archivos"></pqrForm>
      <br>
  </v-form>
  <v-row>
    <v-col cols="12">
      <v-btn @click="validar" block :color="colores.primario" dark>GUARDAR</v-btn>
    </v-col>
  </v-row>
  <br><br><br>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
  <mensajeLogin :dialogoLogin="dialogoLogin" @close_login="cerrar_login"></mensajeLogin>
  </div>
</template>

<script>
import mensajeLogin from '@/components/widgets/mensajes_loginComponent.vue'
import registradorForm from '@/components/inbound/inbound_registradorComponent.vue'
/* import ciudadanoForm from '@/components/inbound/inbound_ciudadanoComponent.vue' */
import pqrForm from '@/components/inbound/inbound_pqrComponent.vue'
import buscarForm from '@/components/inbound/inbound_busquedaComponent.vue'
import recTipicaciones from '@/js/rec_tipicaciones.js'
import recGestiones from '@/js/rec_gestiones.js'
import recFuciones from '@/js/rec_funciones.js'
import recPaises from '@/js/rec_paises.js'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'

export default {
  name: 'dialogo_inboundComponent',
  components: {
    registradorForm,
    /* ciudadanoForm, */
    pqrForm,
    buscarForm,
    mensajesSave,
    mensajeLogin
  },
  mixins: [recTipicaciones, recGestiones, recFuciones, recPaises],
  props: ['tokenInbound', 'llamada', 'colores', 'loadingAtenciones', 'items_atenciones', 'session', 'tiempo', 'items_busqueda', 'items_tipoUsuario', 'items_tipoIdentificacion', 'items_tipoComite', 'items_votoBlanco'],
  data: () => ({
    pqr: { keyform: 0, id_usuario: 0, tipoatencion_id: 113, subcategoria_id: null, detalle_id: null, descripcion: '', segundos: 0, subestado_id: 1, escalado_id: 1, fechadiagnostico: '', fechaescalado: '', fechafinalizado: '', fechaparacerrar: '', fechacerrado: null },
    registrador: { gestion_id: 0, cedula: '', nombre: '', apellido: '', departamento_id: 0, ciudad_id: null, registraduria_id: 2327, direccion: '', kit: '', telefono: '', celular: '', tipoUsuario: '', tipoComite: '', votoBlanco: '' },
    ciudadano: { gestion_id: 0, cedula: '', nombre: '', correo: '', pais_id: 0 },
    alerta: { diagnostico: 0, tipod: '', escalado: 0, tipoe: '', finalizado: 0, tipof: '', total: 0, tipot: '' },
    loadingSubcategoria: false,
    items_subcategorias: [],
    loadingPaises: false,
    items_paises: [],
    loadingDepartamento: false,
    items_departamentos: [],
    rules: {},
    items_archivos: [],
    items_niveles: [],
    items_errores: [],
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    dialogoLogin: false,
    tokenNuevo: 0
  }),
  created () {
    this.rules = this.$store.getters.getRules
    this.rec_paises_departamentos()
    this.rec_tipificaciones_subcategorias(553)
  },
  watch: {
    tokenInbound: {
      immediate: true,
      handler (newVal, oldVal) {
        this.rec_gestion_inicializacion_pqr()
        this.rec_gestion_inicializacion_ciudadano()
        this.rec_gestion_inicializacion_registrador()
        this.pqr.keyform = this.rec_funciones_key()
        this.items_archivos = []
      }
    }
  },
  methods: {
    agregar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    },
    input_tipoatencion (ptipoatencionId) {
      this.eliminarNiveles()
      this.pqr.subcategoria_id = 0
      this.pqr.detalle_id = 0
      // this.rec_tipificaciones_subcategorias(ptipoatencionId)
    },
    validar () {
      if (this.$refs.form.validate()) {
        this.pqr.id_usuario = this.session.id
        if (this.pqr.id_usuario === 0) {
          this.dialogo.incompleto = true
          this.dialogoLogin = true
        } else {
          this.dialogo.pregunta = true
        }
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_login () {
      this.dialogoLogin = false
    },
    eliminarNiveles () {
      this.items_niveles = []
    },
    agregarNiveles (pniveles, color, escestadoId, escestado) {
      this.items_niveles.push({ gestion_id: 0, fecha_asignado: null, fecha_cerrado: null, id_usuario: this.session.id, consecutivo: pniveles.consecutivo, area_id: pniveles.area.id, area: pniveles.area.descripcion, escestado_id: escestadoId, escestado: escestado, color: color })
      // this.items_niveles.push({ gestion_id: 0, consecutivo: pniveles.consecutivo, area_id: pniveles.area.id, area: pniveles.area.descripcion, escestado_id: escestadoId, escestado: escestado, color: color, fecha_asignado: null, fecha_cerrado: null })
      // this.items_niveles.push({ gestion_id: 0, consecutivo: pniveles[i].consecutivo, area_id: pniveles[i].area.id, area: pniveles[i].area.descripcion, escestado_id: escestadoId, escestado: escestado, color: color, fecha_asignado: '', fecha_cerrado: '' })
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_nuevo_tipo()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    }

  }
}
</script>
