<template>
  <div style="width:100%">
    <v-dialog v-model="dialogInbound" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark :color="colores.primario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>CREAR PQR</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-card-text>
            <horaComponent :tokenTiempo="tokenInbound" @eventotiempo="tiempo_pqr"></horaComponent>
          </v-card-text>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-2">
        <formInbound :llamada="llamada" :tokenInbound="tokenInbound" :colores="colores" :tiempo="tiempo" :session="session" :loadingAtenciones="loadingAtenciones" :items_atenciones="items_atenciones" :items_busqueda="items_busqueda" @new_pqr="nueva_pqr" :items_tipoUsuario="items_tipoUsuario" :items_tipoIdentificacion="items_tipoIdentificacion" :items_tipoComite="items_tipoComite" :items_votoBlanco="items_votoBlanco"></formInbound>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import horaComponent from '@/components/widgets/horaComponent.vue'
import formInbound from '@/components/inbound/inbound_formComponent.vue'
import recForm from '@/js/rec_form.js'

export default {
  name: 'dialogo_inboundComponent',
  components: {
    horaComponent,
    formInbound
  },
  mixins: [recForm],
  props: ['dialogInbound', 'colores', 'tokenInbound', 'session'],
  data: () => ({
    llamada: { gestion_id: 0, celular: '', callercode: '', tipo: '', cedula: '', uniqueid: '', security_phrase: '', lead_id: '', vendor_id: '', closer: '', server_ip: '', closecallid: '', xfercallid: '' },
    tiempo: 0,
    loadingAtenciones: true,
    items_atenciones: [],
    items_busqueda: [],
    items_tipoUsuario: [],
    items_tipoIdentificacion: [],
    items_tipoComite: [],
    items_votoBlanco: []
  }),
  created () {
    this.rec_form_inbound()
  },
  methods: {
    cerrarVenta () {
      this.$emit('closed_inbound')
    },
    tiempo_pqr (ptiempo) {
      this.tiempo = ptiempo
    },
    nueva_pqr () {
      this.$emit('new_tiempo')
    }
  }
}
</script>
