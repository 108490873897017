<template>
  <div style="width:100%">
  <v-row no-gutters class="pb-2">
    <v-col cols="12" md="1">
      <v-avatar :color="colores.primario" size="40" class="white--text">{{ item.texto }}</v-avatar>
    </v-col>
    <v-col cols="12" md="10">
      <template v-for="(area, index) in items_anual.areas">
        <v-row :key="index">
          <v-col>
            {{ area }}
          </v-col>
        </v-row>
      </template>
    </v-col>
    <v-col cols="12" md="1">
      <v-btn :loading="loadingMes" @click="buscar_mes(item)" icon :color="(seleccion_mes===item.mes)?'orange lighten-1':'blue darken-2'"> <v-icon dark>mdi-chart-pie</v-icon></v-btn>
    </v-col>
  </v-row>
  <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: 'estadisticas_anualComponent',
  components: {

  },
  props: ['colores', 'item', 'seleccion_mes', 'items_anual', 'loadingMes'],
  data: () => ({
  }),
  created () {
  },
  methods: {
    buscar_mes (item) {
      this.$emit('view_mes', item)
    }

  }
}
</script>
