import axios from 'axios'
export default {
  methods: {
    rec_bandeja_all (pBuscar) {
      if (pBuscar === undefined) {
        pBuscar = 'TODOS'
      }
      if (this.session.area > 0) {
        this.rec_bandeja_escalamientos(pBuscar, this.session.area)
      } else {
        this.rec_bandeja_creados(pBuscar, this.session.id)
      }
    },
    rec_bandeja_escalamientos (pBuscar, pAreaId) {
      var urlroute = this.$store.getters.getUrl + 'escalamientos_enproceso/' + pAreaId
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        if (pBuscar === 'TODOS') {
          this.items_escalamientos = response.data
        } else if (pBuscar === 'PROXIMOS A VENCER') {
          this.items_escalamientos = response.data.filter(item => { return (item.totalhoras > -3) })
        } else if (pBuscar === 'PRIORIDAD BAJA') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 3) })
        } else if (pBuscar === 'PRIORIDAD MEDIO') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 2) })
        } else if (pBuscar === 'PRIORIDAD ALTA') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 1) })
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
      })
    },
    rec_bandeja_creados (pBuscar, pIdUsuario) {
      var urlroute = this.$store.getters.getUrl + 'escalamientos_creo/' + pIdUsuario
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        this.items_cerrar = response.data.cerrar
        if (pBuscar === 'TODOS') {
          this.items_creados = response.data.escalamiento
        } else if (pBuscar === 'PROXIMOS A VENCER') {
          this.items_creados = response.data.escalamiento.filter(item => { return (item.totalhoras > -3) })
        } else if (pBuscar === 'PRIORIDAD BAJA') {
          this.items_creados = response.data.escalamiento.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 3) })
        } else if (pBuscar === 'PRIORIDAD MEDIO') {
          this.items_creados = response.data.escalamiento.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 2) })
        } else if (pBuscar === 'PRIORIDAD ALTA') {
          this.items_creados = response.data.escalamiento.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 1) })
        } else if (pBuscar === 'NIVELES') {
          this.items_creados = []
          // this.items_creados = response.data.filter(item => { return (item.escalamientos.escestado_id === 2) })
          const nivel = parseInt(this.formbuscar.nivel)
          for (let i = 0; i < response.data.escalamiento.length; i++) {
            let estado = 0
            for (let j = 0; j < response.data.escalamiento[i].escalamientos.length; j++) {
              if (response.data.escalamiento[i].escalamientos[j].consecutivo === nivel && response.escalamiento.data[i].escalamientos[j].escestado_id === 2) {
                estado = 1
              }
            }
            if (estado === 1) {
              this.items_creados.push(response.data[i])
            }
          }
        } else if (pBuscar === 'AREAS') {
          this.items_creados = []
          // this.items_creados = response.data.filter(item => { return (item.escalamientos.escestado_id === 2) })
          const itemsTepm = []
          for (let i = 0; i < response.data.length; i++) {
            let estado = 0
            for (let j = 0; j < response.data[i].escalamientos.length; j++) {
              if (response.data[i].escalamientos[j].area_id === this.formbuscar.area && (response.data[i].escalamientos[j].escestado_id === 2 || response.data[i].escalamientos[j].escestado_id === 9)) {
                estado = 1
              }
            }
            if (estado === 1) {
              itemsTepm.push(response.data[i])
            }
          }

          if (this.formbuscar.afiltro === 'TODOS') {
            this.items_creados = itemsTepm
          } else if (this.formbuscar.afiltro === 'PROXIMOS A VENCER') {
            this.items_creados = itemsTepm.filter(item => { return (item.subestado_id === 2 && item.totalhoras > -3) })
          } else if (this.formbuscar.afiltro === 'PRIORIDAD BAJA') {
            this.items_creados = itemsTepm.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 3) })
          } else if (this.formbuscar.afiltro === 'PRIORIDAD MEDIO') {
            this.items_creados = itemsTepm.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 2) })
          } else if (this.formbuscar.afiltro === 'PRIORIDAD ALTA') {
            this.items_creados = itemsTepm.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 1) })
          }
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
      })
    },
    rec_bandeja_pausa () {
      this.loadingBandeja = true
      var urlroute = this.$store.getters.getUrl + 'escalamientos_pausa'
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        this.items_escalamientos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBandeja = false
      })
    },
    rec_bandeja_escalamientos_all (pBuscar) {
      this.loadingBandeja = true
      var urlroute = this.$store.getters.getUrl + 'escalamientos_enproceso_all'
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        if (pBuscar === 'TODOS') {
          this.items_escalamientos = response.data
        } else if (pBuscar === 'PROXIMOS A VENCER') {
          this.items_escalamientos = response.data.filter(item => { return (item.totalhoras > -3) })
        } else if (pBuscar === 'PRIORIDAD BAJA') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 3) })
        } else if (pBuscar === 'PRIORIDAD MEDIO') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 2) })
        } else if (pBuscar === 'PRIORIDAD ALTA') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 1) })
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBandeja = false
      })
    },
    rec_bandeja_escalamientos_niveles (pNivel) {
      this.loadingBandeja = true
      var urlroute = this.$store.getters.getUrl + 'escalamientos_por_nieveles/ ' + pNivel
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        this.items_escalamientos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBandeja = false
      })
    },
    rec_bandeja_escalamientos_areas (pArea, pBuscar) {
      this.loadingBandeja = true
      var urlroute = this.$store.getters.getUrl + 'escalamientos_por_areas/' + pArea
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        if (pBuscar === 'TODOS') {
          this.items_escalamientos = response.data
        } else if (pBuscar === 'PROXIMOS A VENCER') {
          this.items_escalamientos = response.data.filter(item => { return (item.totalhoras > -3) })
        } else if (pBuscar === 'PRIORIDAD BAJA') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 3) })
        } else if (pBuscar === 'PRIORIDAD MEDIO') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 2) })
        } else if (pBuscar === 'PRIORIDAD ALTA') {
          this.items_escalamientos = response.data.filter(item => { return (item.accion !== null && item.accion.prioridad.id === 1) })
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBandeja = false
      })
    }
  }
}
