<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>SECCIÓN DATOS BASICOS</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete :rules="[rules.requerido]" v-model="registrador.tipoUsuario" item-text="descripcion" item-value="descripcion" :loading="loadingDepartamento" :items="items_tipoUsuario" label="TIPO USUARIO"  autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col v-if="registrador.tipoUsuario === 'AGRUPACIÓN POLÍTICA'" cols="12" md="6" class="pr-1">
          <v-autocomplete :rules="[rules.requerido]" v-model="registrador.tipoComite" item-text="descripcion" item-value="descripcion" :loading="loadingDepartamento" :items="items_tipoComite" label="AGRUPACIONES POLÍTICAS"  autocomplete="off"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete :rules="[rules.requerido]" v-model="registrador.votoBlanco" item-text="descripcion" item-value="descripcion" :loading="loadingDepartamento" :items="items_votoBlanco" label="PROMOTOR DEL VOTO EN BLANCO"  autocomplete="off"></v-autocomplete>        
        </v-col>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field v-model="registrador.cedula" v-on:blur="blur_buscar(registrador.cedula)" :success="isSuccess" maxlength="25" counter="25" label="No. IDENTIFACIÓN" :rules="[rules.requerido]" autocomplete="off">
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-progress-circular v-if="isloadingIden" size="24" indeterminate></v-progress-circular>
                </v-fade-transition>
              </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-autocomplete :rules="[rules.requerido]" v-model="registrador.tipoIdentificacion" item-text="descripcion" item-value="descripcion" :loading="loadingDepartamento" :items="items_tipoIdentificacion" label="TIPO DOCUMENTO"  autocomplete="off"></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
            <v-text-field v-model="registrador.nombre"  maxlength="90" counter="90" label="NOMBRES" :rules="[rules.requerido]" autocomplete="off"></v-text-field>
          </v-col>
      </v-row>
      <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-text-field v-model="registrador.apellido"  maxlength="90" counter="90" label="APELLIDOS" :rules="[rules.requerido]" autocomplete="off"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="pr-1">
              <v-text-field v-model="registrador.telefono"  maxlength="10" counter="10" label="NUMERO DE CONTACTO"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="false">
              <v-text-field v-model="registrador.celular"  maxlength="10" counter="10" label="CELULAR"></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-autocomplete v-model="registrador.departamento_id" v-on:input="input_departamento(registrador.departamento_id)" item-text="descripcion" item-value="id" :loading="loadingDepartamento" :items="items_departamentos" label="DEPARTAMENTO"  autocomplete="off"></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="registrador.ciudad_id" clearable v-on:input="input_ciudad(registrador.ciudad_id)" item-text="descripcion" item-value="id" :loading="loadingCiudad" :items="items_ciudades" label="MUNICIPIO"  autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import recPaises from '@/js/rec_paises.js'
import recRegistradurias from '@/js/rec_registradurias.js'
import recRegistradores from '@/js/rec_registradores.js'
export default {
  name: 'dialogo_inboundComponent',
  components: {
  },
  mixins: [recPaises, recRegistradurias, recRegistradores],
  props: ['registrador', 'colores', 'rules', 'loadingDepartamento', 'items_departamentos', 'items_tipoUsuario', 'items_tipoIdentificacion', 'items_tipoComite', 'items_votoBlanco'],
  data: () => ({
    loadingCiudad: false,
    items_ciudades: [],
    loadingRegistraduria: false,
    items_registradurias: [],
    isSuccess: false,
    isloadingIden: false,
    showTiempo: false,
    estadoTiempo: 2
  }),
  methods: {
    blur_buscar (pCedula) {
      this.rec_registradores_cedula(pCedula)
    },
    input_departamento (pDepartamentoId) {
      this.rec_paises_ciudades(pDepartamentoId)
    },
    input_ciudad (pciudadId) {
      this.rec_registraduria_ciudad(pciudadId)
    },
    tiempo_pqr (ptiempo) {
      this.tiempo = ptiempo
    },
    editar () {
      this.estadoTiempo = 1
      this.showTiempo = true
      this.editable = true
    }
  }
}
</script>
