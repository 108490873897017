export default {
  methods: {
    rec_niveles_nuevo (pestadoId) {
      if (pestadoId === 3) { // DEVUELTO

      } else if (pestadoId === 4) { // SIGUIENTE NIVEL
        this.items_niveles[1].color = 'amber'
        this.items_niveles[1].escestado_id = 2
        this.items_niveles[1].escestado = 'EN PROCESO'

        this.items_niveles[0].color = 'teal'
        this.items_niveles[0].escestado_id = 5
        this.items_niveles[0].escestado = 'NIVEL CERRADO'
      } else if (pestadoId === 6) { // CERRAR CASO
        this.items_niveles[0].escestado_id = 6
        this.items_niveles[0].escestado = 'CERRAR CASO'
        this.items_niveles[0].color = 'teal'

        if (this.items_niveles.length > 1) {
          this.items_niveles[1].color = 'blue-grey'
          this.items_niveles[1].escestado_id = 1
          this.items_niveles[1].escestado = ''
        }
      } else if (pestadoId === 2) { // EN PROCESO
        this.items_niveles[0].color = 'amber'
        this.items_niveles[0].escestado_id = 6
        this.items_niveles[0].escestado = 'EN PROCESO'

        if (this.items_niveles.length > 1) {
          this.items_niveles[1].color = 'blue-grey'
          this.items_niveles[1].escestado_id = 1
          this.items_niveles[1].escestado = ''
        }
      } else if (pestadoId === 9) { // EN VALIDACION
        this.items_niveles[0].color = 'deep-orange'
        this.items_niveles[0].escestado_id = 9
        this.items_niveles[0].escestado = 'EN VALIDACIÓN'

        if (this.items_niveles.length > 1) {
          this.items_niveles[1].color = 'blue-grey'
          this.items_niveles[1].escestado_id = 1
          this.items_niveles[1].escestado = ''
        }
      }
    },
    rec_niveles_editar (pestadoId) {
      const escalamientos = this.registro.escalamientos.map(item => { return item })

      for (let i = 0; i < escalamientos.length; i++) {
        this.items_niveles[i].escestado.color = escalamientos[i].escestado.color
        this.items_niveles[i].escestado.descripcion = escalamientos[i].escestado.descripcion
        this.items_niveles[i].escestado_id = escalamientos[i].escestado_id
        this.items_niveles[i].escestado.id = escalamientos[i].escestado.id
      }

      let proceso = 0
      for (let i = 0; i < escalamientos.length; i++) {
        if (escalamientos[i].escestado_id === 2 || escalamientos[i].escestado_id === 9) {
          proceso = i
          break
        } else if (escalamientos[i].escestado_id === 7) {
          proceso = i
          break
        } else if (escalamientos[i].escestado_id === 10) {
          proceso = i
          break
        }
      }

      if (pestadoId === 3) { // DEVUELTO
        this.items_niveles[(proceso - 1)].escestado.color = 'amber'
        this.items_niveles[(proceso - 1)].escestado_id = 2
        this.items_niveles[(proceso - 1)].escestado.id = 2
        this.items_niveles[(proceso - 1)].escestado.descripcion = 'EN PROCESO'

        this.items_niveles[proceso].escestado.color = 'blue-grey'
        this.items_niveles[proceso].escestado.descripcion = ''
        this.items_niveles[proceso].escestado_id = 1
        this.items_niveles[proceso].escestado.id = 1
      } else if (pestadoId === 4) { // SIGUIENTE NIVEL
        this.items_niveles[proceso].escestado.color = 'teal'
        this.items_niveles[proceso].escestado.descripcion = 'NIVEL CERRADO'
        this.items_niveles[proceso].escestado_id = 5
        this.items_niveles[proceso].escestado.id = 5

        this.items_niveles[(proceso + 1)].escestado.color = 'amber'
        this.items_niveles[(proceso + 1)].escestado_id = 2
        this.items_niveles[(proceso + 1)].escestado.id = 2
        this.items_niveles[(proceso + 1)].escestado.descripcion = 'EN PROCESO'
      } else if (pestadoId === 6) { // CERRAR CASO
        this.items_niveles[proceso].escestado.color = 'teal'
        this.items_niveles[proceso].escestado.descripcion = 'CERRAR CASO'
        this.items_niveles[proceso].escestado_id = 6
        this.items_niveles[proceso].escestado.id = 6
      } else if (pestadoId === 7) { // PAUSA
        this.items_niveles[proceso].escestado.color = 'purple'
        this.items_niveles[proceso].escestado.descripcion = 'SOLICITAR PAUSA'
        this.items_niveles[proceso].escestado_id = 7
        this.items_niveles[proceso].escestado.id = 7
      } else if (pestadoId === 8) { // QUITAR PAUSA
        this.items_niveles[proceso].escestado.color = 'amber'
        this.items_niveles[proceso].escestado_id = 2
        this.items_niveles[proceso].escestado.id = 2
        this.items_niveles[proceso].escestado.descripcion = 'EN PROCESO'
      } else if (pestadoId === 10) { // ACEPTAR PAUSA
        this.items_niveles[proceso].escestado.color = 'indigo'
        this.items_niveles[proceso].escestado_id = 10
        this.items_niveles[proceso].escestado.id = 10
        this.items_niveles[proceso].escestado.descripcion = 'ACEPTAR PAUSA'
      } else if (pestadoId === 11) { // RECHAZAR PAUSA
        this.items_niveles[proceso].escestado.color = 'blue'
        this.items_niveles[proceso].escestado_id = 11
        this.items_niveles[proceso].escestado.id = 11
        this.items_niveles[proceso].escestado.descripcion = 'RECHAZAR PAUSA'
      } else if (pestadoId === 9) { // EN VALIDACION
        this.items_niveles[proceso].escestado.color = 'deep-orange'
        this.items_niveles[proceso].escestado.descripcion = 'EN VALIDACIÓN'
        this.items_niveles[proceso].escestado_id = 9
        this.items_niveles[proceso].escestado.id = 9

        this.items_niveles[(proceso + 1)].escestado.color = 'blue-grey'
        this.items_niveles[(proceso + 1)].escestado_id = 1
        this.items_niveles[(proceso + 1)].escestado.id = 1
        this.items_niveles[(proceso + 1)].escestado.descripcion = ''
      }
    }

  }
}
