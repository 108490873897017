<template>
  <div style="width:100%">
    <v-container fluid>
        <v-card>
          <v-tabs v-model="tab" :background-color="colores.secundario" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">BANDEJA<v-icon>mdi-inbox</v-icon></v-tab>
            <v-tab href="#tab-2" v-if="false">BASES<v-icon>mdi-database</v-icon></v-tab>
            <!-- <v-tab href="#tab-3">CONFIGURACION<v-icon>mdi-cog-outline</v-icon></v-tab> -->
            <v-tab href="#tab-4">APLICATIVOS<v-icon>mdi-apps</v-icon></v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" :value="'tab-1'">
              <v-card flat><v-card-text> <bandejegaPqrs :colores="colores" :items_areas="items_areas" :session="session" :formbuscar="formbuscar" :items_abusquedas="items_abusquedas" :items_niveles="items_niveles" :items_busquedas="items_busquedas" :items_escalamientos="items_escalamientos" :items_creados="items_creados" @new_inbound="nuevo_inbound" @refresh_listado="refrescar_listado" :items_cerrar="items_cerrar"></bandejegaPqrs>  </v-card-text></v-card>
            </v-tab-item>
            <v-tab-item :key="2" :value="'tab-2'">
              <v-card flat><v-card-text>  </v-card-text></v-card>
            </v-tab-item>
            <v-tab-item :key="3" :value="'tab-3'">
              <v-card flat><v-card-text>  <dialogoConfiguracion></dialogoConfiguracion>  </v-card-text></v-card>
            </v-tab-item>
            <v-tab-item :key="4" :value="'tab-4'">
              <v-card flat><v-card-text> <bandejeApliactivo :colores="colores" :session="session"></bandejeApliactivo> </v-card-text></v-card>
            </v-tab-item>
          </v-tabs-items>
      </v-card>
      <!-- <chat :myRight="380"></chat> -->
      <!--<chat :myRight="720"></chat>  -->
    </v-container>
    <dialogoInbound :dialogInbound="dialogInbound" :colores="colores" :session="session" :tokenInbound="tokenInbound" @closed_inbound="dialogInbound=false" @new_tiempo="nuevo_tiempo"></dialogoInbound>
  </div>
</template>

<script>
// import chat from '@/components/chats/chatComponent.vue'
import bandejegaPqrs from '@/components/bandejas/bandeja_pqrsComponent.vue'
import bandejeApliactivo from '@/components/bandejas/bandeja_aplicativosComponent.vue'
import dialogoConfiguracion from '@/components/bandejas/bandeja_configuracionCompont.vue'
import dialogoInbound from '@/components/inbound/inbound_dialogComponent.vue'

export default {
  name: 'bandejaComponent',
  components: {
    // chat,
    bandejegaPqrs,
    dialogoConfiguracion,
    dialogoInbound,
    bandejeApliactivo
  },
  props: ['colores', 'session', 'formbuscar', 'items_niveles', 'items_areas', 'items_busquedas', 'items_abusquedas', 'items_escalamientos', 'items_creados', 'items_cerrar'],
  data: () => ({
    tab: null,
    dialogInbound: false,
    tokenInbound: 0
  }),
  methods: {
    refrescar_listado () {
      this.$emit('update_pqrs')
    },
    nuevo_inbound () {
      this.tokenInbound = this.tokenInbound + 1
      this.dialogInbound = true
    },
    nuevo_tiempo () {
      this.tokenInbound = this.tokenInbound + 1
    }

  }
}
</script>
