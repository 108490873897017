import axios from 'axios'
export default {
  methods: {
    rec_escestados_escalado () {
      var urlroute = this.$store.getters.getUrl + 'grupos_estados'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_estados = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {

      })
    }

  }
}
