import axios from 'axios'
export default {
  methods: {
    rec_registradores_cedula (pCedula) {
      this.isloadingIden = false
      this.isSuccess = false
      this.items_atenciones = []
      var urlroute = this.$store.getters.getUrl + 'registradores_cedula/' + pCedula
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        if (response.data !== '') {
          this.rec_registradores_entidad(response.data)
          this.isSuccess = true
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.isloadingIden = false
      })
    },
    rec_registradores_entidad (data) {
      this.registrador.gestion_id = 0
      this.registrador.nombre = data.nombre
      this.registrador.departamento_id = data.departamento_id
      this.registrador.ciudad_id = data.ciudad_id
      this.registrador.registraduria_id = 2327 /* data.registraduria_id */
      this.registrador.direccion = ''
      this.registrador.kit = data.kit
      this.registrador.telefono = data.telefono
      this.registrador.celular = data.celular
      this.registrador.apellido = data.apellido
      this.registrador.tipoUsuario = data.tipoUsuario
      this.registrador.tipoIdentificacion = data.tipoIdentificacion
      this.registrador.tipoComite = data.TipoComite
      this.registrador.votoBlanco = data.votoBlanco
      this.rec_paises_ciudades(data.departamento_id)
      this.rec_registraduria_ciudad(data.ciudad_id)
    }

  }
}
