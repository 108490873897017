<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>SECCIÓN DATOS BASICOS</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="6"  class="pr-1">
            <v-text-field label="TIPO USUARIO" :value="registrador.tipoUsuario" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="TIPO DOCUMENTO" :value="registrador.tipoIdentificacion" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="AGRUPACIONES POLÍTICAS" :value="registrador.TipoComite" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="PROMOTOR DEL VOTO EN BLANCO" :value="registrador.votoBlanco" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6"  class="pr-1">
            <v-text-field label="CEDULA" :value="registrador.cedula" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="NOMBRE" :value="registrador.nombre" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="APELLIDO" :value="registrador.apellido" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field label="NUMERO DE CONTACTO" :value="registrador.telefono" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="false">
            <v-text-field label="CELULAR" :value="registrador.celular" readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <v-text-field label="DEPARTAMENTO" :value="registrador.departamento.descripcion" readonly></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="MUNICIPIO" :value="registrador.ciudad.descripcion" readonly></v-text-field>
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
export default {
  name: 'inbound_lectura_registradorComponent',
  components: {
  },
  props: ['colores', 'registrador'],
  data: () => ({

  }),
  created () {

  },
  methods: {
  }
}
</script>
