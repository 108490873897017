<template>
<div style="width:100%">
<br>

<v-row no-gutters>
  <template v-for="(item, index) in escalamientos" >
    <v-col cols="12" md="3" class="pr-1" :key="index">
        <nivelCard :color="item.escestado.color" :escestado="item.escestado.descripcion" :titulo="'NIVEL ' + item.consecutivo" :descripcion="item.area.descripcion"></nivelCard>
    </v-col>
  </template>
</v-row>
</div>
</template>

<script>
import nivelCard from '@/components/inbound/inbound_nivelesComponent.vue'
export default {
  name: 'inbound_lectura_nivlesComponent',
  components: {
    nivelCard
  },
  props: ['colores', 'escalamientos'],
  data: () => ({

  }),
  created () {

  },
  methods: {
  }
}
</script>
