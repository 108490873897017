<template>
  <div style="width:100%">
    <v-dialog v-model="dialogoLogin" persistent transition="dialog-top-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>LOGIN</v-toolbar>
            <v-card-text>
              <div class="text-h6">DEBE ESTAR LOGEADO EN EL APPMASTER PRIMERO</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="cerrar_venta">CERRAR</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['dialogoLogin'],
  data: () => ({
  }), // FIN DE DATA
  created () {}, // FIN DE CREATED
  watch: {}, // FIN DE WATCH
  methods: {
    cerrar_venta () {
      this.$emit('close_login')
    }
  } // FIN DE METODOS
}
</script>
