<template>
  <div style="width:100%">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app v-if="session.tipo === 'Interno'">
      <v-card :color="colores.grey" dark>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><strong>LLAMADAS</strong></v-list-item-title>
            <v-list-item-subtitle>Historial</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="buscar" :loading="loadingLLamada" icon v-bind="attrs" v-on="on"><v-icon>mdi-refresh</v-icon></v-btn>
              </template>
              <span>Actualizar</span>
            </v-tooltip>
        </v-list-item-action>
        </v-list-item>
      </v-card>
      <v-card-text class="pt-0 pb-0">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu = false" locale="es"></v-date-picker>
        </v-menu>
      </v-card-text>
      <v-divider></v-divider>
      <template v-for="(item, index) in items_llamadas">
        <div :key="index">
          <v-list-item>
              <v-list-item-avatar><v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><strong>{{ item.telefono }}</strong></v-list-item-title>
                <v-list-item-subtitle>{{ item.tiempo }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="abrirVenta(item)">
                  <v-icon :color="colores.primario">mdi-book-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <audio controls :src="item.location" controlsList="nodownload"></audio>
            </v-list-item>
            <v-divider></v-divider>
        </div>
      </template>
    </v-navigation-drawer>

  </div>
</template>

<script>
import recVicidial from '@/js/rec_vicidial.js'
export default {
  name: 'MenuLeft',
  props: ['colores', 'session'],
  mixins: [recVicidial],
  data: () => ({
    drawer: true,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    loadingLLamada: false,
    items_llamadas: []
  }),
  methods: {
    buscar () {
      this.rec_vicidial_llamadas(this.date, this.session.identificacion)
    },
    abrirVenta (item) {
      const url = 'celular=' + item.telefono + '&callercode=' + item.callercode + '&tipo=' + item.tipo + '&cedula=' + ' &uniqueid=' + item.uniqueid + '&security_phrase=' + item.phrase + '&vendor_id=' + item.phrase + '&lead_id=' + item.lead_id + '&closer=' + item.closer + '&server_ip=' + item.server_ip + '&closecallid=' + item.closecallid + '&xfercallid=' + item.xfercallid
      window.open(window.location.origin + '/vicidial?' + url, '_blank')
    }
  }
}
</script>
