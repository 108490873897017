import axios from 'axios'
export default {
  methods: {
    rec_gestion_inicializacion_pqr () {
      this.pqr.keyform = 0
      this.pqr.subcategoria_id = null
      this.pqr.detalle_id = null
      this.pqr.descripcion = ''
      this.pqr.segundos = 0
      this.pqr.subestado_id = 1
      this.pqr.escalado_id = 1
      this.items_niveles = []
    },
    rec_gestion_inicializacion_ciudadano () {
      this.ciudadano.gestion_id = 0
      this.ciudadano.cedula = ''
      this.ciudadano.nombre = ''
      this.ciudadano.correo = ''
      this.ciudadano.pais_id = 0
    },
    rec_gestion_inicializacion_registrador () {
      this.registrador.gestion_id = 0
      this.registrador.cedula = ''
      this.registrador.nombre = ''
      this.registrador.departamento_id = 0
      this.registrador.ciudad_id = null
      this.registrador.registraduria_id = 2327
      this.registrador.direccion = ''
      this.registrador.kit = ''
      this.registrador.telefono = ''
      this.registrador.celular = ''
      this.registrador.apellido = ''
      this.registrador.tipoUsuario = ''
      this.registrador.tipoIdentificacion = ''
      this.registrador.tipoComite = ''
      this.registrador.votoBlanco = ''
    },
    rec_gestion_nuevo_tipo () {
      this.pqr.id_usuario = this.session.id
      this.pqr.segundos = this.tiempo
      if (this.pqr.tipoatencion_id === 113 || this.pqr.tipoatencion_id === 151) { // ATENCIÓN REGISTRADORES
        this.rec_gestion_nuevo_tiporegistrador()
      } else if (this.pqr.tipoatencion_id === 114) { // ATENCIÓN CIUDADANOS
        this.rec_gestion_nuevo_tipociudadano()
      }
    },
    rec_gestion_nuevo_tipociudadano () {
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      var urlroute = this.$store.getters.getUrl + 'gestion_guardar_tipociudadano'
      const data = {
        ciudadano: this.ciudadano,
        pqr: this.pqr,
        niveles: this.items_niveles,
        tiempos: this.alerta,
        llamada: this.llamada
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.dialogo.detalle = true
        this.dialogo.objPayload = response.data
        this.rec_gestion_inicializacion_pqr()
        this.rec_gestion_inicializacion_ciudadano()
        this.rec_gestion_inicializacion_registrador()
        this.pqr.keyform = this.rec_funciones_key()
        this.$emit('new_pqr')
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_nuevo_tiporegistrador () {
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null
      var urlroute = this.$store.getters.getUrl + 'gestion_guardar_tiporegistrador'
      const data = {
        pqr: this.pqr,
        registrador: this.registrador,
        niveles: this.items_niveles,
        tiempos: this.alerta,
        llamada: this.llamada
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.dialogo.detalle = true
        this.dialogo.objPayload = response.data
        this.rec_gestion_inicializacion_pqr()
        this.rec_gestion_inicializacion_ciudadano()
        this.rec_gestion_inicializacion_registrador()
        this.pqr.keyform = this.rec_funciones_key()
        this.tokenNuevo = this.tokenNuevo + 1
        this.$emit('new_pqr')
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_guardar_log_datos () {
      this.form.gestion_id = this.registro.id
      this.form.id_usuario = this.session.id
    },
    rec_gestion_guardar_log () {
      this.rec_gestion_guardar_log_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      var urlroute = this.$store.getters.getUrl + 'gestion_guadar_logs'
      const data = {
        registro: this.registro,
        form: this.form,
        niveles: this.items_niveles,
        keyform: this.keyform,
        id_usuario: this.session.id,
        tiempos: this.registro.accion
      }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.form.descripcion = ''
        this.objEstado = null
        this.$emit('update_registro')
        this.dialogo.finalizo = true
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_gestion_id (dato) {
      this.loadingBusqueda = true
      var urlroute = this.$store.getters.getUrl + 'gestion_id/' + dato
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_escalamientos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBusqueda = false
      })
    },
    rec_gestion_mesCerrar (anio, pMes) {
      this.loadingMes = true
      const urlroute = this.$store.getters.getUrl + 'gestion_mesCerrado/' + anio + '/' + pMes
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        console.log(response.data)
        this.items_escalamientos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingMes = false
      })
    },
    rec_gestion_mes (pMes) {
      this.loadingMes = true
      const urlroute = this.$store.getters.getUrl + 'gestion_mes/' + pMes
      axios({
        url: urlroute,
        method: 'GET',
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        },
        data: null
      }).then(response => {
        console.log(response.data)
        this.items_escalamientos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingMes = false
      })
    },
    rec_gestion_buscar (ptipoAtencion, pTipo, pDato) {
      this.loadingBusqueda = true
      this.items_registros = []
      var urlroute = this.$store.getters.getUrl + 'consultas_buscar/' + ptipoAtencion + '/' + pTipo + '/' + pDato
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_registros = response.data
        // this.rec_gestion_buscar_entidad(response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBusqueda = false
      })
    },
    rec_gestion_buscar_id (pId) {
      this.loadingBusqueda = true
      this.items_registros = []
      var urlroute = this.$store.getters.getUrl + 'consultas_gestion_id/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.registro = response.data.gestion
        this.rec_gestion_niveles_entidad(response.data.escalamientos.escalamientos)
        this.rec_gestion_estados_entidad(this.registro, response.data.gestion.escalamientos, response.data.estados)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingBusqueda = false
      })
    },
    rec_gestion_niveles_entidad (escalamientos) {
      this.items_niveles = []
      this.items_niveles = [...escalamientos]
    },
    rec_gestion_estados_entidad (registro, escalamientos, estados) {
      this.items_estados = []
      if (registro.subestado_id === 6) {
        this.items_estados = estados.cierrefinal
      } else {
        const t = (escalamientos.length === 0) ? 0 : (escalamientos.length - 1)
        for (let i = 0; i < escalamientos.length; i++) {
          if (escalamientos[i].escestado_id === 2 || escalamientos[i].escestado_id === 9) {
            if (escalamientos[i].area_id === 1) {
              // console.log('el caso esta en nivel 1 por devuelto')
              this.items_estados = estados.primerNivel
            } else if (i < t) {
              this.items_estados = (registro.pausas.length >= 1) ? estados.niveles.filter(item => { return (item.id !== 11) }) : estados.niveles
            } else {
              this.items_estados = (registro.pausas.length >= 1) ? estados.cerrar.filter(item => { return (item.id !== 11) }) : estados.cerrar
            }
          } else if (escalamientos[i].escestado_id === 7) {
            this.items_estados = estados.aceptarpausa
          } else if (escalamientos[i].escestado_id === 10) {
            this.items_estados = estados.quitarpausa
          } else if (escalamientos[i].escestado_id === 9) {
            if (i < t) {
              this.items_estados = estados.quitarvalidacion
            } else {
              this.items_estados = estados.quitarvalidacion
              this.items_estados.splice(0, 1)
            }
          }
        }
      }

      if (this.session.tipo !== 'Externo') {
        for (let i = 0; i < this.items_estados.length; i++) {
          if (this.items_estados[i].id === 3) {
            this.items_estados.splice(i, 1)
          }
        }
      }
    },
    rec_gestion_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'gestion_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_gestion_log_download (pId, archivo) {
      var urlroute = this.$store.getters.getUrl + 'log_download/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', archivo) // set custom file name
        document.body.appendChild(link)
        link.click() // force download file without open new tab
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    }

  }
}
