<template>
<div style="width:100%">
  <v-list>
    <template v-for="(item, index) in session.permisos">
    <div :key="index">
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar  color="indigo" size="36"><span class="white--text text-h6"> {{ (index + 1) }} </span></v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title>{{ item.aplicativo.titulo }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
              <v-btn @click="abrirVentana(item)" icon>
                <v-icon color="grey lighten-1">{{ item.aplicativo.icono }}</v-icon>
              </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
    </div>
    </template>
  </v-list>
  <estadisticasDialogo :colores="colores" :dialogEstadistica="dialogEstadistica" @closed_estadisticas="cerrar_estadisticas"></estadisticasDialogo>
  <estadisticasDialogoBandeja :colores="colores" :session="session" :dialogEstadisticaBandeja="dialogEstadisticaBandeja" @closed_estadisticas_bandeja="cerrar_estadisticas_bandeja"></estadisticasDialogoBandeja>
  <estadisticasPausa :colores="colores" :session="session" :dialogEstadisticaPausa="dialogEstadisticaPausa" @closed_estadisticas_bandeja="cerrar_estadisticas_bandeja"></estadisticasPausa>
</div>
</template>

<script>
import estadisticasDialogo from '@/components/estadisticas/estadisticas_dialogoComponent.vue'
import estadisticasDialogoBandeja from '@/components/estadisticas/estadisticas_bandeja_dialogoComponent.vue'
import estadisticasPausa from '@/components/estadisticas/estadisticas_pausa.vue'
export default {
  name: 'bandejaAplicativosComponent',
  components: {
    estadisticasDialogo,
    estadisticasDialogoBandeja,
    estadisticasPausa
  },
  props: ['session', 'colores'],
  data: () => ({
    dialogEstadistica: false,
    dialogEstadisticaBandeja: false,
    dialogEstadisticaPausa: false

  }),
  methods: {
    cerrar_estadisticas () {
      this.dialogEstadistica = false
    },
    abrirVentana (item) {
      if (item.aplicativo_id === 1) {
        this.dialogEstadistica = true
      } else if (item.aplicativo_id === 2) {
        this.dialogEstadisticaBandeja = true
      } else if (item.aplicativo_id === 3) {
        window.open('http://172.17.8.108/websigplus/usuariovalidarcrm.php?pid=90&pusu=' + this.session.id, '_blank')
      } else if (item.aplicativo_id === 4) {
        window.open('http://172.17.8.108/websigplus/usuariovalidarcrm.php?pid=1000&pusu=' + this.session.id, '_blank')
      } else if (item.aplicativo_id === 5) {
        this.dialogEstadisticaPausa = true
      }
    },
    cerrar_estadisticas_bandeja () {
      this.dialogEstadisticaBandeja = false
      this.dialogEstadisticaPausa = false
    }

  }
}
</script>
