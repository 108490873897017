<template>
<div style="width:100%">
  <br>
  <v-card>
    <v-card-text class="pt-1 pr-4 pl-4 pb-1">
      <v-row no-gutters>
        <v-col cols="12" md="6" class="pr-1">
          <v-text-field label="ID" :value="registro.id" readonly></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field label="CREADO" :value="rec_function_fecha_colombia(registro.created_at)" readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-text-field label="TIPO DE ATENCION" :value="registro.tipoatencion.descripcion" readonly></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
<br>
<!-- <ciudadanoLectura v-if="registro.gesciudadano" :ciudadano="registro.gesciudadano" :colores="colores"></ciudadanoLectura> -->
<registradorLectura v-if="registro.gesregistrador" :registrador="registro.gesregistrador" :colores="colores"></registradorLectura>
<br>
<pqrLectura :items_archivos="items_archivos" :keyform="keyform" :session="session" :colores="colores" :registro="registro" :items_estados="items_estados" :items_niveles="items_niveles" @update_niveles="actualizar_niveles" @update_registro="actualizar_registro" @add_archivos="agregar_archivos"></pqrLectura>
<logsLectura :colores="colores" :logs="registro.logs"></logsLectura>
</div>
</template>

<script>
// import ciudadanoLectura from '@/components/inbound/inbound_lectura_ciudadanoComponent.vue'
import registradorLectura from '@/components/inbound/inbound_lectura_registradorComponent.vue'
import pqrLectura from '@/components/inbound/inbound_lectura_pqrComponent.vue'
import logsLectura from '@/components/inbound/inbound_lectura_logsComponent.vue'
import recEscestados from '@/js/rec_escestados.js'
import recFunciones from '@/js/rec_funciones.js'

export default {
  name: 'inbound_lecturaComponent',
  components: {
    // ciudadanoLectura,
    registradorLectura,
    pqrLectura,
    logsLectura
  },
  mixins: [recEscestados, recFunciones],
  props: ['colores', 'keyform', 'session', 'registro', 'items_estados', 'items_niveles'],
  data: () => ({
    items_archivos: []
  }),
  created () {
  },
  methods: {
    agregar_archivos (data) {
      for (let i = 0; i < data.length; i++) {
        this.items_archivos.push(data[i])
      }
    },
    actualizar_niveles (pestadoId) {
      this.$emit('refresh_niveles', pestadoId)
    },
    actualizar_registro () {
      this.$emit('refresh_registro')
    }
  }
}
</script>
