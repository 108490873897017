<template>
  <div style="width:100%">
    <v-container fluid>
      <center>
      <span class='text-h4 blue--text'><strong>REPORTES DE {{ objMes.completo }} </strong></span>
      </center>
      <br>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn color="green" block dark>GESTION</v-btn>
        </v-col>
      </v-row>
      <estadisticaMes></estadisticaMes>
    </v-container>
  </div>
</template>

<script>
import estadisticaMes from '@/components/estadisticas/estadisticas_pormesComponent.vue'
export default {
  name: 'estadisticas_mesComponent',
  components: {
    estadisticaMes
  },
  props: ['colores', 'anio', 'objMes'],
  data: () => ({
  }),
  created () {
  },
  methods: {

  }
}
</script>
