import axios from 'axios'
export default {
  methods: {
    rec_tipificaciones_subcategorias (pId) {
      this.loadingSubcategoria = true
      this.items_subcategorias = []
      this.loadingPaises = true
      this.items_paises = []
      this.loadingDepartamento = true
      this.items_departamentos = []
      var urlroute = this.$store.getters.getUrl + 'listados_principal_tipo/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_paises = response.data.paises
        this.items_subcategorias = response.data.subcategorias
        this.items_departamentos = response.data.departamentos
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingSubcategoria = false
        this.loadingPaises = false
        this.loadingDepartamento = false
      })
    },
    rec_tipificaciones_detalles (pId) {
      this.loadingDetalle = true
      this.items_detalles = []
      var urlroute = this.$store.getters.getUrl + 'listados_principal_niveles/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_detalles = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDetalle = false
      })
    },
    rec_solucion (detalleId) {
      this.loadingDetalle = true
      this.items_solucion = []
      var urlroute = this.$store.getters.getUrl + 'listados_solucion/' + detalleId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_solucion = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDetalle = false
      })
    },
    rec_tipificaciones_detalle (idDetalle) {
      this.loadingDetalle = true
      this.items_detalless = []
      var urlroute = this.$store.getters.getUrl + 'listados_principal_detalle/' + idDetalle
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_detalless = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDetalle = false
      })
    }
  }
}
