<template>
  <div style="width:100%">
    <v-dialog v-model="dialogEstadisticaBandeja" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark :color="colores.primario">
          <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>ALERTAS</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="buscar" :items="items_busquedas" label="FILTROS"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="buscar === 'NIVELES'">
              <v-autocomplete v-model="nivel" :items="items_niveles" label="NIVELES" item-text="descripcion" item-value="descripcion"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="buscar === 'ID DE CASO'">
              <v-text-field v-model="dato" label="* DATO A BUSCAR" autocomplete="off"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" v-if="buscar === 'AREAS'">
              <v-autocomplete v-model="area" :items="items_areas" label="AREAS" item-text="descripcion" item-value="id"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" align-self="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="actualizar" :loading="loadingBandeja" block v-bind="attrs" v-on="on"> <v-icon>mdi-refresh</v-icon> </v-btn>
                </template>
                <span>Refrescar</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="buscar === 'AREAS'">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-autocomplete v-model="afiltro" :items="items_abusquedas" label="FILTROS"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-4" v-if="buscar==='REPORTES' || buscar==='CERRADO POR MES'">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="anio" label="* AÑO" color="primary"> </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete v-model="mes" :items="items_meses" item-text="descripcion" item-value="id" label="* MES" color="primary"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="buscar==='REPORTES'">
            <v-col cols="12" md="6">
              <v-btn color="green" :loading="loadingExcel" @click="reportes('GESTIONES')" block dark>EXCEL GESTIONES</v-btn>
            </v-col>
            <v-col cols="12" md="6">
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-4" v-if="buscar!=='REPORTES'">
        <span class="text-h4"><strong>TOTAL: {{ items_escalamientos.length }} </strong></span>
        </v-card-text>
        <v-card-text class="pt-4" v-if="buscar!=='REPORTES'">
          <bandejegaListado :colores="colores" :session="session" :items_creados="items_creados" :items_escalamientos="items_escalamientos"></bandejegaListado>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
import bandejegaListado from '@/components/bandejas/bandeja_pqrs_listadoComponent.vue'
import recBandeja from '@/js/rec_bandeja.js'
import recForm from '@/js/rec_form.js'
import recReportes from '@/js/rec_reportes.js'
import recFunciones from '@/js/rec_funciones.js'
import recBuscar from '@/js/rec_gestiones.js'
import recBuscarMes from '@/js/rec_estadisticas.js'

export default {
  name: 'estadisticas_bandeja_dialogoComponent',
  components: {
    bandejegaListado
  },
  mixins: [recBandeja, recForm, recFunciones, recReportes, recBuscar, recBuscarMes],
  props: ['dialogEstadisticaBandeja', 'colores', 'session', 'items_busqueda'],
  data: () => ({
    loadingBandeja: false,
    items_creados: [],
    items_escalamientos: [],
    nivel: 0,
    area: 0,
    loadingExcel: false,
    afiltro: 'TODOS',
    items_niveles: [],
    items_areas: [],
    buscar: 'TODOS',
    items_busquedas: ['TODOS', 'PRIORIDAD BAJA', 'PRIORIDAD MEDIO', 'PRIORIDAD ALTA', 'PROXIMOS A VENCER', 'NIVELES', 'AREAS', 'REPORTES', 'ID DE CASO', 'CERRADO POR MES'],
    items_abusquedas: ['TODOS', 'PRIORIDAD BAJA', 'PRIORIDAD MEDIO', 'PRIORIDAD ALTA', 'PROXIMOS A VENCER', 'ID DE CASO'],
    items_meses: [{ id: '01', descripcion: 'ENERO' }, { id: '02', descripcion: 'FEBRERO' }, { id: '03', descripcion: 'MARZO' }, { id: '04', descripcion: 'ABRIL' }, { id: '05', descripcion: 'MAYO' }, { id: '06', descripcion: 'JUNIO' }, { id: '07', descripcion: 'JULIO' }, { id: '08', descripcion: 'AGOSTO' }, { id: '09', descripcion: 'SEPTIEMBRE' }, { id: '10', descripcion: 'OCTUBRE' }, { id: '11', descripcion: 'NOVIEMBRE' }, { id: '12', descripcion: 'DICIEMBRE' }],
    anio: 0,
    dato: '',
    mes: ''
  }),
  created () {
    this.actualizar()
    this.rec_form_alertas()
    this.anio = this.rec_funciones_anio()
    this.mes = this.rec_funciones_mes()
  },
  methods: {
    actualizar () {
      if (this.buscar === 'NIVELES') {
        this.rec_bandeja_escalamientos_niveles(this.nivel)
      } else if (this.buscar === 'AREAS') {
        this.rec_bandeja_escalamientos_areas(this.area, this.afiltro)
      } else if (this.buscar === 'ID DE CASO') {
        this.rec_gestion_id(this.dato)
      } else if (this.buscar === 'CERRADO POR MES') {
        this.rec_gestion_mesCerrar(this.anio, this.mes)
      } else if (this.buscar === 'REPORTES') {
      } else {
        this.rec_bandeja_escalamientos_all(this.buscar)
      }
    },
    reportes (pMenu) {
      if (pMenu === 'GESTIONES') {
        this.rec_reportes_gestiones(this.anio, this.mes)
      }
    },
    cerrarVenta () {
      this.$emit('closed_estadisticas_bandeja')
    }

  }
}
</script>
