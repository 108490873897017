import axios from 'axios'
export default {
  methods: {
    rec_form_inbound () {
      this.loadingAtenciones = true
      this.items_atenciones = []
      var urlroute = this.$store.getters.getUrl + 'form_inbound'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        console.log(response.data)
        this.items_atenciones = response.data.atenciones
        this.items_busqueda = response.data.tipobusquedas
        this.items_tipoUsuario = response.data.usuarios
        this.items_tipoIdentificacion = response.data.usuariosdocumento
        this.items_tipoComite = response.data.tipocomite
        this.items_votoBlanco = response.data.votoblanco
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingAtenciones = false
      })
    },
    rec_form_alertas () {
      var urlroute = this.$store.getters.getUrl + 'form_alertas'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_niveles = response.data.niveles
        this.items_areas = response.data.areas
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingDetalle = false
      })
    }

  }
}
