import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Bandeja from '../views/Bandeja.vue'
import Telefonia from '../views/Telefonia.vue'
import Vicidial from '../views/Vicidial.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  }, {
    path: '/bandeja',
    name: 'Bandeja',
    component: Bandeja
  }, {
    path: '/telefonia',
    name: 'Telefonia',
    component: Telefonia
  }, {
    path: '/vicidial',
    name: 'Vicidial',
    component: Vicidial
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/', // thosmasgreg  //process.env.BASE_URL,
  routes
})

export default router
