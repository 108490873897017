<template>
  <div style="width:100%">
    <v-dialog v-model="dialogEstadisticaPausa" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark :color="colores.primario">
          <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>SOLUCIONES EN PAUSA</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="actualizar" :loading="loadingBandeja" block v-bind="attrs" v-on="on"> <v-icon>mdi-refresh</v-icon> </v-btn>
                </template>
                <span>Refrescar</span>
              </v-tooltip>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-4" v-if="buscar!=='REPORTES'">
        <span class="text-h4"><strong>TOTAL: {{ items_escalamientos.length }} </strong></span>
        </v-card-text>
        <v-card-text class="pt-4" v-if="buscar!=='REPORTES'">
          <bandejegaListado :colores="colores" :session="session" :items_creados="items_creados" :items_escalamientos="items_escalamientos"></bandejegaListado>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
import bandejegaListado from '@/components/bandejas/bandeja_pqrs_listadoComponent.vue'
import recBandeja from '@/js/rec_bandeja.js'
import recForm from '@/js/rec_form.js'
import recFunciones from '@/js/rec_funciones.js'

export default {
  name: 'estadisticas_pausa',
  components: {
    bandejegaListado
  },
  mixins: [recBandeja, recForm, recFunciones],
  props: ['dialogEstadisticaPausa', 'colores', 'session'],
  data: () => ({
    loadingBandeja: false,
    items_creados: [],
    items_escalamientos: [],
    afiltro: 'TODOS',
    buscar: 'TODOS',
    items_busquedas: ['TODOS'],
    items_abusquedas: ['TODOS']
  }),
  created () {
    this.actualizar()
    this.rec_form_alertas()
  },
  methods: {
    actualizar () {
      this.rec_bandeja_pausa(this.buscar)
    },
    cerrarVenta () {
      this.$emit('closed_estadisticas_bandeja')
    }
  }
}
</script>
