<template>
  <div style="width:100%">
    <v-dialog v-model="dialogEstadistica" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark :color="colores.primario">
          <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
          <v-toolbar-title>CREAR PQR</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field label="AÑO" :value="anio"></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-btn @click="buscar_anio" :loading="loadingAnio" block><v-icon dark>mdi-magnify</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <estadisticaAnual :colores="colores" :anio="anio" :items_mes="items_mes" :items_anual="items_anual"></estadisticaAnual>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
import recEstadisticas from '@/js/rec_estadisticas.js'
import estadisticaAnual from '@/components/estadisticas/estadisticas_anualComponent.vue'

export default {
  name: 'estadisticas_dialogoComponent',
  components: {
    estadisticaAnual
  },
  mixins: [recFunciones, recEstadisticas],
  props: ['dialogEstadistica', 'colores'],
  data: () => ({
    anio: 0,
    loadingAnio: false,
    items_anual: [],
    items_mes: [{ mes: 1, texto: 'ENE', completo: 'ENERO', numero: '01' }, { mes: 2, texto: 'FEB', completo: 'FEBRERO', numero: '02' }, { mes: 3, texto: 'MAR', completo: 'MARZO', numero: '03' }, { mes: 4, texto: 'ABR', completo: 'ABRIL', numero: '04' }, { mes: 5, texto: 'MAY', completo: 'MAYO', numero: '05' }, { mes: 6, texto: 'JUN', completo: 'JUNIO', numero: '06' }, { mes: 7, texto: 'JUL', completo: 'JULIO', numero: '07' }, { mes: 8, texto: 'AGO', completo: 'AGOSTO', numero: '08' }, { mes: 9, texto: 'SEP', completo: 'SEPTIEMBRE', numero: '09' }, { mes: 10, texto: 'OCT', completo: 'OCTUBRE', numero: '10' }, { mes: 11, texto: 'NOV', completo: 'NOVIEMBRE', numero: '11' }, { mes: 12, texto: 'DIC', completo: 'DICIEMBRE', numero: '12' }]
  }),
  created () {
    this.anio = this.rec_funciones_anio()
  },
  methods: {
    cerrarVenta () {
      this.$emit('closed_estadisticas')
    },
    buscar_anio () {
      this.rec_estadisticas_anio(this.anio)
    }

  }
}
</script>
