<template>
<div style="width:100%">
  <br>
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>LOGS</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
        <div v-for="(item, index) in logs" :key="index">
          <v-card>
            <v-toolbar dense :color="colores.grey" dark>
              <v-toolbar-title style="font-size:14px"> <v-icon size="20">mdi-calendar</v-icon> {{ rec_function_fecha_colombia(item.created_at) }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon size="20">mdi-account</v-icon>
              {{ (item.usuario === null) ? '' : (item.usuario.nombre_usuario + ' ' + item.usuario.apellido_usuario) }}
            </v-toolbar>
            <v-card-text>
              <v-chip small class="ma-2" :color="(item.escestado === null)? '' : item.escestado.color" dark>{{ item.escestado.descripcion}}</v-chip>
              <br>
              {{ item.descripcion }}
            </v-card-text>
            <v-card-text>
              <v-list dense>
                  <div v-for="(archivo, index) in item.logarchivos" :key="index">
                    <filesLectura :colores="colores" :tipo="'LOG'" :item="archivo"></filesLectura>
                    <v-divider></v-divider>
                  </div>
              </v-list>
            </v-card-text>
          </v-card>
          <br>
        </div>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import filesLectura from '@/components/inbound/inbound_lectura_archivosComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'inbound_lectura_ciudadanoComponent',
  components: {
    filesLectura
  },
  mixins: [recFunciones],
  props: ['colores', 'logs'],
  data: () => ({

  }),
  created () {

  },
  methods: {
  }
}
</script>
