import axios from 'axios'
export default {
  methods: {
    rec_upload_gestion (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'gestion_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        // this.desserts.splice(i, 1)
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    },
    rec_upload_log (pId, i) {
      this.loadingEliminar = true
      var urlroute = this.$store.getters.getUrl + 'log_eliminar/' + pId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_archivos[i].estado_id = 2
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingEliminar = false
      })
    }

  }
}
