import axios from 'axios'
export default {
  methods: {
    rec_vicidial_llamadas (fecha, cedula) {
      this.loadingLLamada = true
      var urlroute = this.$store.getters.getUrl + 'vicidial_llamadas/' + cedula + '/' + fecha
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        this.items_llamadas = []
        const data = response.data
        for (let i = 0; i < data.length; i++) {
          const fechaInicio = this.rec_vicidial_hora(data[i].start_time)
          const fechaFin = this.rec_vicidial_hora(data[i].end_time)
          const filename = data[i].filename
          const tiempo = this.rec_vicidial_tiempo(data[i].length_in_sec)
          const vicidialId = data[i].vicidial_id
          const extension = data[i].extension
          const location = data[i].location
          const leadId = data[i].lead_id
          const serverIp = data[i].server_ip
          const tipo = this.rec_vicidial_tipo(filename)
          const callercode = this.rec_vicidial_callercode(filename)
          const telefono = this.rec_vicidial_telefono(filename)
          let uniqueid, closecallid, xfercallid, phrase
          if (data[i].vicidial !== null) {
            uniqueid = data[i].vicidial.uniqueid
            closecallid = data[i].vicidial.closecallid
            xfercallid = data[i].vicidial.xfercallid
            phrase = data[i].vicidial.campaign_id
          } else {
            uniqueid = ''
            closecallid = ''
            xfercallid = ''
            phrase = ''
          }
          const closer = 0
          let icon, iconClass
          if (tipo === 'OUTBOUND') {
            icon = 'mdi-phone-forward'
            iconClass = 'blue-grey darken-3 accent-4 lighten-1 white--text'
          } else {
            icon = 'mdi-phone-incoming'
            iconClass = 'pink darken-4 white--text'
          }
          this.items_llamadas.push({ fila: i, icon: icon, iconClass: iconClass, tipo: tipo, fecha_inicio: fechaInicio, fecha_fin: fechaFin, filename: filename, extension: extension, vicidial_id: vicidialId, tiempo: tiempo, telefono: telefono, location: location, phrase: phrase, uniqueid: uniqueid, callercode: callercode, lead_id: leadId, server_ip: serverIp, closecallid: closecallid, xfercallid: xfercallid, closer: closer, musica: 'PENDIENTE' })
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingLLamada = false
      })
    },
    rec_vicidial_tiempo (time) {
      let result = ''
      if (time !== null) {
        let hours = Math.floor(time / 3600)
        let minutes = Math.floor((time % 3600) / 60)
        let seconds = time % 60
        hours = hours < 10 ? '0' + hours : hours
        // Anteponiendo un 0 a los minutos si son menos de 10
        minutes = minutes < 10 ? '0' + minutes : minutes
        // Anteponiendo un 0 a los segundos si son menos de 10
        seconds = seconds < 10 ? '0' + seconds : seconds
        result = hours + ':' + minutes + ':' + seconds // 2:41:30
      }
      return result
    },
    rec_vicidial_telefono (filename) {
      let temp1, datos, explo, telefono
      telefono = ''
      if (filename) {
        temp1 = filename.split('-')
        if (temp1.toString().length > 1) {
          datos = temp1[1]
          explo = datos.split('_')
          if (explo.length > 1) {
            telefono = explo[explo.length - 2]
          }
        }
        // OUT_20190425-082914_SBSGARA_1019038696_46145599_Y4250828540000122672
      }
      return telefono
    },
    rec_vicidial_tipo (filename) {
      let temp1, datos, explo, tipo, temp2, t
      tipo = ''
      if (filename !== null) {
        temp1 = filename.split('-')
        if (temp1 !== null) {
          if (temp1.toString().length > 1) {
            datos = temp1[1]
            explo = datos.split('_')
            if (explo.length > 1) {
              temp2 = explo[explo.length - 1]
              if (temp2.length > 1) {
                t = temp2.substring(0, 1)
                if (t === 'M') {
                  tipo = 'OUTBOUND'
                } else {
                  tipo = 'INBOUND'
                }
              }
            }
          }
        }
      }
      return tipo
    },
    rec_vicidial_callercode (filename) {
      let temp1, datos, explo, tipo
      tipo = ''
      if (filename !== null) {
        temp1 = filename.split('-')
        if (temp1 !== null) {
          if (temp1.toString().length > 1) {
            datos = temp1[1]
            explo = datos.split('_')
            if (explo.length > 1) {
              tipo = explo[explo.length - 1]
            }
          }
        }
      }
      return tipo
    },
    rec_vicidial_hora (pfecha) {
      let tiempo = ''
      if (pfecha !== null) {
        if (pfecha.toString().length >= 10) {
          tiempo = pfecha.substring(10)
          tiempo = tiempo.trim()
        }
      }
      return tiempo
    }

  }
}
