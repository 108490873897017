<template>
<div style="width:100%">
  <v-card :color="color" elevation="3" dark>
    <v-card-title class="text-h5">{{ titulo }}</v-card-title>
    <v-card-subtitle>{{ descripcion }}</v-card-subtitle>
    <v-divider></v-divider>
    <v-card class="pl-4 pt-1" flat color="transparent">{{ escestado }}</v-card>
  </v-card>
</div>
</template>

<script>

export default {
  name: 'inbound_nivelesComponent',
  components: {
  },
  props: ['color', 'titulo', 'escestado', 'descripcion'],
  data: () => ({

  }),
  methods: {

  }
}
</script>
