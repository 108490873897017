<template>
  <div style="width:100%">
    sss
  </div>
</template>

<script>

export default {
  name: 'estadisticas_pormesComponent',
  components: {

  },
  props: ['colores', 'anio', 'objMes'],
  data: () => ({
  }),
  created () {
  },
  methods: {

  }
}
</script>
