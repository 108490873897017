<template>
<div style="width:100%">
    <div v-if="showUsuario">
      <menuTop :colores="colores" :session="session"></menuTop>
      <menusLeft :colores="colores" :session="session"></menusLeft>
      <!-- <menusRight :colores="colores"></menusRight> -->
      <bandeja :colores="colores" :session="session" :formbuscar="formbuscar" :items_cerrar="items_cerrar" :items_areas="items_areas" :items_niveles="items_niveles" :items_abusquedas="items_abusquedas" :items_busquedas="items_busquedas" :items_creados="items_creados" :items_escalamientos="items_escalamientos" @update_pqrs="actualizar_pqrs"></bandeja>
    </div>
    <div v-if="!showUsuario">
      <center>
        <br><br>
        <v-icon size="50">mdi-account</v-icon>
        <br>
        <span class="text-h6">VALIDANDO USUARIO</span>
        <br><br><br>
        <v-progress-circular :size="150" :width="7" :color="colores.primario" indeterminate></v-progress-circular>
      </center>
    </div>
  </div>
</template>

<script>
import menuTop from '@/components/menus/menu_topComponent.vue'
import menusLeft from '@/components/menus/menu_leftComponent.vue'
// import menusRight from '@/components/menus/menu_rightComponent.vue'
import bandeja from '@/components/bandejas/bandejaComponent.vue'
import recBandeja from '@/js/rec_bandeja.js'
import recUsuario from '@/js/rec_usuario.js'
import recForm from '@/js/rec_form.js'

export default {
  name: 'Bandeja',
  components: {
    menuTop,
    menusLeft,
    // menusRight,
    bandeja
  },
  mixins: [recBandeja, recUsuario, recForm],
  data: () => ({
    colores: null,
    session: { id: 0, nombres: '', apellidos: '', correo: '', identificacion: '', tipo: '', area: 0, permisos: [] },
    items_escalamientos: [],
    items_creados: [],
    items_cerrar: [],
    showUsuario: false,
    formbuscar: { buscar: 'TODOS', afiltro: 'TODOS', nivel: 0, area: 0 },
    items_niveles: [],
    items_areas: [],
    items_busquedas: ['TODOS', 'PRIORIDAD BAJA', 'PRIORIDAD MEDIO', 'PRIORIDAD ALTA', 'PROXIMOS A VENCER', 'NIVELES', 'AREAS'],
    items_abusquedas: ['TODOS', 'PRIORIDAD BAJA', 'PRIORIDAD MEDIO', 'PRIORIDAD ALTA', 'PROXIMOS A VENCER']
  }),
  created () {
    // SERVER LOCAL
    const usu = this.$route.query.idusu
    if (usu !== undefined) {
      this.session.id = this.$route.query.idusu
    } else {
      this.session = this.$store.getters.getSession
    }

    // SERVER PUBLICOs
    /*
    const s = this.$store.getters.getSession
    if (s.id > 0) {
      this.session = this.$store.getters.getSession
    }
    */

    // const s = this.$store.getters.getSession
    /* if (s.id > 0) {
      this.session = this.$store.getters.getSession
    } else {
      this.session.id = this.$route.query.idusu
    }
    */
    this.colores = this.$store.getters.getColores
    this.rec_usuario_validar()
    this.rec_form_alertas()
  },
  methods: {
    actualizar_pqrs () {
      this.rec_bandeja_all(this.formbuscar.buscar)
    },
    startInterval: function () {
      setInterval(() => {
        // 10000 - 10 segundos
        // 60000 - 60 segundos
        // 120000 - 2 minutos
        // 240000 - 4 minutos
        this.rec_bandeja_all(this.formbuscar.buscar)
      }, 30000)
    }
  }
}
</script>
