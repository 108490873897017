<template>
<div style="width:100%">
<v-form ref="form">
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>TIPIFICACIÓN</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" class="pr-1">
          <v-text-field label="CATEGORIA" :value="registro.subcategoria.descripcion" readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field label="SUBCATEGORIA" :value="registro.detalle.descripcion" readonly></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-textarea label="SOLUCIÓN" :value="registro.detalle.solucion" readonly></v-textarea>
        </v-col>
      </v-row>
        <v-divider></v-divider>
        <v-row no-gutters>
          <v-col cols="12">
            <textComponent :titulo="'DESCRIPCION'" :descripcion="registro.descripcion"></textComponent>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <label class="text-h5 blue--text" v-if="registro.gesarchivos.length > 0"><strong>ARCHIVOS</strong></label>
        <v-list dense>
              <div v-for="(item, index) in registro.gesarchivos" :key="index">
                <filesLectura :colores="colores" :tipo="'GESTION'" :item="item"></filesLectura>
                <v-divider></v-divider>
              </div>
        </v-list>
    </v-card-text>
    <v-card-text>
      <nivelesLectura :escalamientos="items_niveles"></nivelesLectura>
    </v-card-text>
    <v-card-text v-if="validar_area">
        <br>
        <v-row no-gutters>
          <v-col cols="12">
            <v-textarea v-model="form.descripcion" :rules="[rules.requerido]" label="DESCRIPCION"></v-textarea>
          </v-col>
        </v-row>
        <br>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-autocomplete v-model="objEstado" :rules="[rules.requerido]" v-on:input="input_estado(objEstado)" item-text="escestado.descripcion" item-value="escestado.id" :items="items_estados" label="ESTADO" return-object autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters v-if="showErrorPausa">
          <v-col cols="12" md="6">
            <span class='red--text'><strong>TIEMPO YA ESTA VENCIDO PARA COLOCARLO EN PAUSA</strong></span>
          </v-col>
        </v-row>
        <br>
        <v-row no-gutters>
          <v-col cols="12">
            <uploadFile :tipo="'LOG'" :items_archivos="items_archivos" :colores="colores" :session="session" :keyform="keyform" :urlroute="'upload_cargar_editar'" @refresh_archivos="refrescar_archivos"></uploadFile>
          </v-col>
        </v-row>
        <br>
        <v-row no-gutters>
          <v-col cols="12">
            <v-btn @click="validar" :color="colores.primario" dark block>GUARDAR</v-btn>
          </v-col>
        </v-row>
        <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
    </v-card-text>
    <br><br><br><br>
  </v-card>
</v-form>
</div>
</template>

<script>
import filesLectura from '@/components/inbound/inbound_lectura_archivosComponent.vue'
import nivelesLectura from '@/components/inbound/inbound_lectura_nivlesComponent.vue'
import uploadFile from '@/components/widgets/uploadComponent.vue'
import textComponent from '@/components/widgets/textComponent.vue'
import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
import recGestiones from '@/js/rec_gestiones.js'
export default {
  name: 'inbound_lectura_pqrComponent',
  components: {
    textComponent,
    nivelesLectura,
    uploadFile,
    filesLectura,
    mensajesSave
  },
  mixins: [recNiveles, recGestiones, recFunciones],
  props: ['colores', 'items_archivos', 'keyform', 'session', 'registro', 'items_estados', 'items_niveles'],
  data: () => ({
    form: { gestion_id: 0, id_usuario: 0, escestado_id: 0, descripcion: '' },
    objEstado: null,
    rules: {},
    items_errores: [],
    showErrorPausa: false,
    dialogo: { incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null }
  }),
  created () {
    this.rules = this.$store.getters.getRules
  },
  computed: {
    validar_area () {
      let estado = false
      if (this.registro.subestado_id === 6 && this.registro.id_usuario === this.session.id) {
        estado = true
      } else {
        for (let i = 0; i < this.registro.escalamientos.length; i++) {
          if ((this.registro.escalamientos[i].area_id === this.session.area && this.registro.escalamientos[i].escestado_id === 2)) {
            estado = true
          }
          if (this.registro.escalamientos[i].id_usuario === this.session.id && this.session.tipo === 'Interno' && this.registro.escalamientos[i].consecutivo === 1 && this.registro.escalamientos[i].escestado_id === 2) {
            estado = true
          }
          if ((this.registro.escalamientos[i].area_id === this.session.area && this.registro.escalamientos[i].escestado_id === 7)) {
            estado = true
          }
          if (this.registro.escalamientos[i].id_usuario === this.session.id && this.registro.escalamientos[i].consecutivo === 1 && this.registro.escalamientos[i].escestado_id === 9) {
            estado = true
          }
          if ((this.session.tipo === 'Interno' && this.session.area === 8 && (this.registro.escalamientos[i].escestado_id === 7 || this.registro.escalamientos[i].escestado_id === 10))) {
            estado = true
          }
        }
      }
      return estado
    }
  },
  methods: {
    validar () {
      this.showErrorPausa = false
      if (this.$refs.form.validate()) {
        if (this.form.escestado_id === 7) {
          if (this.rec_funciones_fecha_comparar_mayor(this.registro.fechaparacerrar, Date.now()) === false) {
            this.dialogo.pregunta = true
          } else {
            this.showErrorPausa = true
            this.dialogo.incompleto = true
          }
        } else {
          this.dialogo.pregunta = true
        }
      } else {
        this.dialogo.incompleto = true
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_guardar_log()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
        this.dialogo.finalizo = false
      }
    },
    input_estado (obj) {
      this.form.escestado_id = obj.escestado_id
      this.$emit('update_niveles', obj.escestado_id)
      // this.rec_niveles_editar(obj.escestado_id)
    },
    refrescar_archivos (data) {
      this.$emit('add_archivos', data)
    }

  }
}
</script>
