<template>
  <div style="width:100%">
    <v-row>
      <template v-for="(item, index) in items_mes">
        <v-col cols="12" md="6" :key="index">
          <estadisticaAnual :loadingMes="loadingMes" :items_anual="items_anual" :colores="colores" :item="item" :seleccion_mes="seleccion_mes" @view_mes="ver_mes"></estadisticaAnual>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col>
        <estadisticaMes :colores="colores" :anio="anio" :objMes="objMes" :items_meses="items_meses"></estadisticaMes>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import estadisticaAnual from '@/components/estadisticas/estadisticas_anual_mesComponent.vue'
import estadisticaMes from '@/components/estadisticas/estadisticas_mesComponent.vue'
import recEstadisticas from '@/js/rec_estadisticas.js'

export default {
  name: 'estadisticas_anualComponent',
  components: {
    estadisticaAnual,
    estadisticaMes
  },
  mixins: [recEstadisticas],
  props: ['items_mes', 'colores', 'items_anual', 'anio'],
  data: () => ({
    loadingMes: false,
    seleccion_mes: 0,
    objMes: null,
    items_meses: []
  }),
  created () {
  },
  methods: {
    ver_mes (pItem) {
      this.objMes = pItem
      console.log(this.objMes)
      this.seleccion_mes = pItem.mes
      this.rec_estadisticas_mes(this.anio, pItem.mes)
    }

  }
}
</script>
