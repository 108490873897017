import axios from 'axios'
export default {
  methods: {
    rec_usuario_validar () {
      this.showUsuario = false
      var urlroute = this.$store.getters.getUrl + 'usuario_validar/' + this.session.id
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
      }).then(response => {
        let id, identificacion, nombres, apellidos, correo, tipo, area
        const usuario = response.data.usuario
        const asesor = response.data.asesor
        const permisos = response.data.permisos
        area = 0
        this.$store.commit('setAutenticacion', false)
        if (asesor !== '' && asesor !== null) {
          area = asesor.area_id
        }
        if (usuario !== '' && usuario !== null && usuario.id_usuario !== 0) {
          id = usuario.id_usuario
          identificacion = usuario.cedula_usuario
          nombres = usuario.nombre_usuario
          apellidos = usuario.apellido_usuario
          correo = usuario.usuario
          tipo = usuario.tipoUsuario

          this.$store.commit('setAutenticacion', true)
          this.$store.commit('setSession', { id, identificacion, nombres, apellidos, correo, tipo, area, permisos })
          this.showUsuario = true
        } else {
          this.$router.replace({ path: '/' })
          // eslint-disable-next-line handle-callback-err
          this.$router.push('/').catch(err => {})
        }
        this.session = this.$store.getters.getSession
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.rec_bandeja_all()
        this.startInterval()
      })
    }

  }
}
