<template>
<div style="width:100%">
  <v-card>
    <v-toolbar :color="colores.secundario" dark dense>
      <v-toolbar-title>TIPIFICACIÓN</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
              <v-autocomplete :rules="[rules.requerido]" v-model="pqr.subcategoria_id" v-on:input="input_subcategoria(pqr.subcategoria_id)" item-text="descripcion" item-value="id" :items="items_subcategorias" :loading="loadingSubcategoria" label="CATEGORIA" autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
              <v-autocomplete :rules="[rules.requerido]" v-model="objDetalle" v-on:input="input_detalle(objDetalle)" item-text="descripcion" item-value="id" :items="items_detalles" :loading="loadingDetalle" label="SUBCATEGORIA" return-object autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
          <v-row no-gutters v-if="objDetalle">
            <v-col cols="12">
              <v-textarea label="SOLUCIÓN" :value="objDetalle.solucion" readonly></v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
          <v-col cols="12">
            <v-textarea v-model="pqr.descripcion" :rules="[rules.requerido]" label="DESCRIPCION" rows="5"></v-textarea>
          </v-col>
        </v-row>
        <br>
        <v-row no-gutters v-if="objDetalle">
              <v-col cols="12" md="3" class="pr-1" v-for="(item, index) in items_niveles" :key="index">
                  <nivelCard :color="item.color" :escestado="item.escestado" :titulo="'NIVEL ' + item.consecutivo" :descripcion="item.area"></nivelCard>
              </v-col>
        </v-row>
        <br>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-autocomplete :rules="[rules.requerido]" v-model="objEstado"  v-on:input="input_estado(objEstado)" item-text="escestado.descripcion" item-value="escestado.id" :items="items_estados" label="ESTADO" return-object autocomplete="off"></v-autocomplete>
          </v-col>
        </v-row>
        <br>
        <v-row no-gutters>
          <v-col cols="12">
            <uploadFile :tipo="'GESTION'" :colores="colores" :items_archivos="items_archivos" :session="session" :keyform="pqr.keyform" :urlroute="'upload_cargar_nuevo'" @refresh_archivos="refrescar_archivos"></uploadFile>
          </v-col>
        </v-row>
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import nivelCard from '@/components/inbound/inbound_nivelesComponent.vue'
import uploadFile from '@/components/widgets/uploadComponent.vue'
import recTipicaciones from '@/js/rec_tipicaciones.js'
import recNiveles from '@/js/rec_niveles.js'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'dialogo_inboundComponent',
  components: {
    uploadFile,
    nivelCard
  },
  mixins: [recTipicaciones, recNiveles, recFunciones],
  props: ['tokenNuevo', 'colores', 'items_archivos', 'session', 'pqr', 'rules', 'loadingSubcategoria', 'items_subcategorias', 'items_niveles', 'alerta'],
  data: () => ({
    objEstado: null,
    items_estados: [],
    objDetalle: null,
    loadingDetalle: false,
    items_detalles: [],
    showTiempo: false,
    estadoTiempo: 2
  }),
  watch: {
    tokenNuevo: {
      immediate: true,
      handler (newVal, oldVal) {
        this.objDetalle = null
        this.objEstado = null
      }
    }
  },
  methods: {
    refrescar_archivos (data) {
      console.log('1')
      console.log(data)
      console.log('2')
      this.$emit('add_archivos', data)
    },
    input_subcategoria (psubcategoriaId) {
      this.$emit('remover_niveles')
      this.items_estados = []
      this.objEstado = null
      this.rec_tipificaciones_detalles(psubcategoriaId)
    },
    input_solucion (detalleId) {
      this.rec_solucion(detalleId)
    },
    input_detalle (obj) {
      if (obj.accion !== '' && obj.accion !== null) {
        this.alerta.diagnostico = obj.accion.diasdiagnostico
        this.alerta.tipod = obj.accion.tipodiagnostico
        this.alerta.escalado = obj.accion.diasescalados
        this.alerta.tipoe = obj.accion.tipoescalados
        this.alerta.finalizado = obj.accion.diasfinalizado
        this.alerta.tipof = obj.accion.tipofinalizado
        this.alerta.total = obj.accion.diastotal
        this.alerta.tipot = obj.accion.tipototal
      } else {
        this.alerta.diagnostico = 0
        this.alerta.tipod = ''
        this.alerta.escalado = 0
        this.alerta.tipoe = ''
        this.alerta.finalizado = 0
        this.alerta.tipof = ''
        this.alerta.total = 0
        this.alerta.tipot = ''
      }

      this.objEstado = null
      this.items_estados = obj.niveles[0].gruestados
      this.crearNiveles(obj.niveles)
      this.pqr.detalle_id = obj.id
    },
    input_estado (obj) {
      this.pqr.escalado_id = 0
      this.pqr.subestado_id = 0
      this.pqr.fechacerrado = null
      if (obj.escestado_id === 6) {
        this.pqr.escalado_id = 1
        this.pqr.subestado_id = 1
        this.pqr.fechacerrado = ''
      } else if (obj.escestado_id === 9) {
        this.pqr.escalado_id = 3
        this.pqr.subestado_id = 3
        this.pqr.fechacerrado = ''
      } else {
        this.pqr.escalado_id = 2
        this.pqr.subestado_id = 2
        this.pqr.fechacerrado = null
      }
      this.eventosNiveles(obj.escestado_id)
    },
    crearNiveles (pniveles) {
      this.$emit('remover_niveles')
      let color = 'blue-grey'
      let escestadoId = 1
      let escestado = ''

      for (let i = 0; i < pniveles.length; i++) {
        if (i === 0) {
          color = 'amber'
          escestadoId = 2
          escestado = 'EN PROCESO'
        } else {
          color = 'blue-grey'
          escestado = ''
          escestadoId = 1
        }
        this.$emit('new_niveles', pniveles[i], color, escestadoId, escestado)
      }
    },
    eventosNiveles (pestadoId) {
      this.rec_niveles_nuevo(pestadoId)
    }
  }
}
</script>
